var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"py-1"},[_c('label',{staticClass:"pr-2 m-0"},[_vm._v("فلترة حسب")]),_c('v-select',{staticClass:"flex-grow-1",staticStyle:{"max-width":"300px"},attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'ltr' : 'rtl',"label":"name","multiple":"","placeholder":"الوحدات","reduce":function (option) { return option.id; },"options":[{id: null, name: 'الغير مرتبط'} ].concat( _vm.units)},on:{"input":_vm.filterQuestionBank},model:{value:(_vm.SelectedUnitList.list),callback:function ($$v) {_vm.$set(_vm.SelectedUnitList, "list", $$v)},expression:"SelectedUnitList.list"}}),_c('b-button',{staticClass:"btn-icon rounded-circle ml-auto",staticStyle:{"padding":"0.5rem 10px !important"},attrs:{"size":"sm","variant":"flat-secondary","disabled":!_vm.selectedRow.length},on:{"click":function($event){_vm.removeQuestionBanks(_vm.selectedRow); _vm.selectedRow=[]}}},[_c('unicon',{attrs:{"name":"trash-alt","width":"18"}})],1)],1),_c('b-card-body',{staticClass:"px-0"},[(_vm.questionbanksActivePage.length)?_c('vue-colabse-table',{attrs:{"label":"label","align":"right","headers":_vm.questionBanksHeader,"colapseOptions":{
                    enable: true,
                    childrenLabel: 'answers',
                    enableCustomHeadre: true,
                    customHeaderLabel: 'label',
                    header: [
                        {
                            label: 'الإجابات',
                            value: 'answer',
                        }
                    ],
                },"selectOptions":{
                    enable: true,
                    label: 'isCorrect'
                },"lableId":"id","items":_vm.questionbanksActivePage,"borderd":""},on:{"selectChange":_vm.selectionChanged},scopedSlots:_vm._u([{key:"sub-select-row",fn:function(ref){
                var tr = ref.tr;
                var val = ref.val;
return [_c('b-form-radio',{attrs:{"name":'_'+tr.id,"value":val},on:{"change":function($event){return _vm.setQuestionBank(tr)}},model:{value:(tr.rightAnswer),callback:function ($$v) {_vm.$set(tr, "rightAnswer", $$v)},expression:"tr.rightAnswer"}})]}},{key:"colapse-icon",fn:function(){return [_c('unicon',{attrs:{"name":"angle-up"}})]},proxy:true},{key:"item-question",fn:function(ref){
                var val = ref.val;
return [_vm._v(" "+_vm._s(val.substr(0, 40) + (val.length > 40 ? '...' : ''))+" ")]}},{key:"item-id",fn:function(ref){
                var val = ref.val;
return [_c('b-button',{attrs:{"size":"sm","pill":"","variant":"primary"}},[_vm._v(" "+_vm._s(val)+" ")])]}},{key:"item-videoName",fn:function(ref){
                var val = ref.val;
return [_vm._v(" "+_vm._s(!val ? 'غير مرتبط بفيديو' : val)+" ")]}},{key:"item-details",fn:function(ref){
                var tr = ref.tr;
return [_c('b-button',{staticClass:"btn-icon rounded-circle",staticStyle:{"padding":"2px 6px !important"},attrs:{"to":'/question-bank/'+tr.id,"size":"sm","variant":"flat-secondary"}},[_c('unicon',{attrs:{"name":"ellipsis-v","width":"18"}})],1)]}},{key:"item-th",fn:function(ref){
                var tr = ref.tr;
return [_vm._v(" "+_vm._s(tr.answer)+" ")]}}],null,false,3548582965)}):_c('div',{staticClass:"text-center justify-content-center mt-2"},[_c('h4',[_vm._v("لا يوجد أسئلة")])])],1)],1),_c('b-col',{staticClass:"d-flex justify-content-center",attrs:{"cols":"12"}},[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":_vm.questionbanksFirstPage,"total-rows":_vm.mainQuestionBankList.length,"per-page":_vm.questionbankPageLength,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return _vm.questionsPagination(value); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('unicon',{attrs:{"width":"20","name":"angle-right","fill":"royalblue"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('unicon',{attrs:{"width":"20","name":"angle-right","fill":"royalblue"}})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }