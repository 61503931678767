<template>
    <div >
        <!-- <div class="d-flex justify-content-start align-items-center mb-2">
        </div> -->
        <b-card no-body>
            <b-card-header class="py-1">
                <label class="pr-2 m-0">فلترة حسب</label>
                <v-select
                    class="flex-grow-1"
                    :dir="$store.state.appConfig.isRTL ? 'ltr' : 'rtl'"
                    label="name"
                    multiple
                    v-model="SelectedUnitList.list"
                    style="max-width: 300px"
                    placeholder="الوحدات"
                    :reduce="(option) => option.id"
                    :options="[{id: null, name: 'الغير مرتبط'}, ...units]"
                    @input="filterQuestionBank" 
                />
                <b-button
                    size="sm"
                    variant="flat-secondary"
                    class="btn-icon rounded-circle ml-auto"
                    style="padding: 0.5rem 10px !important"
                    :disabled="!selectedRow.length"
                    @click="removeQuestionBanks(selectedRow); selectedRow=[]"
                >
                    <unicon name="trash-alt" width="18"></unicon>
                </b-button>
            </b-card-header>
            <b-card-body class="px-0">
                <vue-colabse-table
                    v-if="questionbanksActivePage.length"
                    label="label"
                    align="right"
                    @selectChange="selectionChanged"
                    :headers="questionBanksHeader"
                    :colapseOptions="{
                        enable: true,
                        childrenLabel: 'answers',
                        enableCustomHeadre: true,
                        customHeaderLabel: 'label',
                        header: [
                            {
                                label: 'الإجابات',
                                value: 'answer',
                            }
                        ],
                    }"
                    :selectOptions="{
                        enable: true,
                        label: 'isCorrect'
                    }"
                    lableId="id"
                    :items="questionbanksActivePage"
                    borderd
                >
                    <template v-slot:sub-select-row="{tr, val}">
                        <b-form-radio v-model="tr.rightAnswer" :name="'_'+tr.id" :value="val" @change="setQuestionBank(tr)"></b-form-radio>
                    </template>
                    <template v-slot:colapse-icon>
                        <unicon name="angle-up"></unicon>
                    </template>
                    <template v-slot:item-question="{val}">
                        {{ val.substr(0, 40) + (val.length > 40 ? '...' : '') }}
                    </template>
                    <template v-slot:item-id="{val}">
                        <b-button
                            size="sm"
                            pill
                            variant="primary"
                        >
                            {{val}}
                        </b-button>
                    </template>
                    <template v-slot:item-videoName="{val}">
                        {{ !val ? 'غير مرتبط بفيديو' : val }}
                    </template>
                    <template v-slot:item-details="{tr}">
                        <b-button
                            :to="'/question-bank/'+tr.id"
                            size="sm"
                            variant="flat-secondary"
                            class="btn-icon rounded-circle"
                            style="padding: 2px 6px !important"
                        >
                            <unicon name="ellipsis-v" width="18"></unicon>
                        </b-button>
                    </template>
                    <template v-slot:item-th="{tr}">
                        {{tr.answer}}
                    </template>
                </vue-colabse-table>
                <div v-else class="text-center justify-content-center mt-2">
                    <h4>لا يوجد أسئلة</h4>
                </div>
            </b-card-body>
        </b-card>
        <b-col cols="12" class="d-flex justify-content-center">
            <b-pagination
                :value="questionbanksFirstPage"
                :total-rows="mainQuestionBankList.length"
                :per-page="questionbankPageLength"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="value => questionsPagination(value)"
            >
                <template #prev-text>
                    <unicon width="20" name="angle-right" fill="royalblue" />
                </template>
                <template #next-text>
                    <unicon width="20" name="angle-right" fill="royalblue" />
                </template>
            </b-pagination>
        </b-col>
    </div>
</template>

<script>
import vSelect from "vue-select";
import {
    BPagination,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    BFormRadio
} from "bootstrap-vue";
import vueColabseTable from "@core/components/vue-colabse-table/vue-colabse-table"
import { mapActions, mapGetters } from "vuex";
import store from "@/store";
import Swal from "sweetalert2";
export default {
    components: {
        vueColabseTable,
        BPagination,
        BButton,
        BCard,
        BCardHeader,
        BCardBody,
        BCol,
        BFormRadio,
        vSelect
    },
    data() {
        return {
            selectedRow: [],
            pageLength: 3,
            questionIds: [],
            questionBanksHeader: [
                {
                    label: "رقم السؤال",
                    value: "id"
                },
                {
                    label: "السؤال",
                    value: "question"
                },
                {
                    label: "اسم الفيديو",
                    value: "videoName"
                },
                {
                    label: "تفاصيل",
                    value: "details"
                }
            ],
            header: [
                {
                    label: "اسم المادة",
                    value: "subjectName"
                },
                {
                    label: "السعر",
                    value: "subjectPrice"
                }
            ],
        };
    },
    created() {
        store.commit("app/UPDATE_CREATE_BUTTON_AND_GLOBALE_SEARCH", {
            isActive: true,
            text: "سؤال جديد",
            fetchingFunction: this.openQuestionBank,
            placeHolder: "ابحث عن سؤال محدد",
            value: "",
            filterFunc: this.filterQuestionBank,
        });
        this.fetchQuestionBanks(0);
        this.fetchUnits()
    },
    computed: {
        ...mapGetters([
            "mainQuestionBankList",
            "questionbankPageLength",
            "questionbanksFirstPage",
            "questionbanksActivePage",
            "SelectedUnitList",
            "units"
        ])
    },
    methods: {
        ...mapActions([
            "fetchQuestionBanks",
            "questionsPagination",
            "removeQuestionBanks",
            "setQuestionBank",
            "openQuestionBank",
            "filterQuestionBank",
            "fetchUnits"
        ]),
        getLessonQuestionBanks(lessonId) {
            this.fetchQuestionBanks(lessonId);
        },
        submitRemoveQuestionBanks() {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-danger"
                },
                buttonsStyling: true
            });
            swalWithBootstrapButtons
                .fire({
                    title: "هل أنت متأكد؟",
                    text: "هل فعلاً تريد حذف المادة ؟",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "نعم!",
                    cancelButtonText: "إلغاء",
                    reverseButtons: false
                })
                .then(result => {
                    if (result.value) {
                        this.removeQuestionBanks(this.questionIds);
                    }
                });
        },
        selectionChanged(id) {
            if(this.selectedRow.indexOf(id) == -1) {
                this.selectedRow.push(id)
            } else {
                const index = this.selectedRow.findIndex((selectedId) => selectedId == id)
                this.selectedRow.splice(index, 1)
            }
            console.log(id, this.selectedRow)
        }
    }
};
</script>
